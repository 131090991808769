.home-2
	background: rgba(219, 236, 254, 0.8)
	.item-list
		+mq-min(lg)
			width: r(1100px)
			margin: 0 auto
		.item
			.icon
				.icon-inner
					border: 2px dashed #61AEF9
					+circle(216px)
					display: flex
					+flex-center
					margin: 0 auto
				figure
					display: flex
					+flex-center
					+circle(200px)
					+bg(primary-600)
					img
						max-height: r(104px)
			.title
				margin-top: r(28px)
				@extend .body-3
				+text(gray-600)
				font-weight: 600
				text-align: center
				+mq-max(lg)
					margin-top: r(12px)