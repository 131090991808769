.global-footer
	background: #272727
	@extend .body-3

.footer-top
	padding: r(52px) 0 r(36px)
	border-bottom: 1px solid rgba(129, 129, 129, 0.5)
	+mq-max(lg)
		padding: r(40px) 0
	
.footer-title
	@extend .body-2
	font-weight: 700
	margin-bottom: r(20px)

.footer-address
	ul
		+layout-col(16px)
		li
			display: flex
			gap: r(8px)
			span
				+fz(18px)
				position: relative
				top: r(2px)
				+flex-width(r(20px))

.footer-map
	.map
		+res-ratio(404,200)
		border-radius: r(8px)
		overflow: hidden

.footer-contact
	.row
		+gap-override(12px)
	.form-group
		+all-text-inputs
			border: 1px solid rgba(231, 238, 216, 0.6)
			+placeholder
				color: rgba(231, 238, 216, 0.6)

.footer-bottom
	display: flex
	flex-wrap: wrap
	padding: r(20px) 0
	@extend .body-4
	justify-content: space-between
	gap: r(8px)
	text-align: center
	+mq-max(sm)
		justify-content: center