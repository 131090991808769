.home-3
	.img
		text-align: right
		position: relative
		+mq-max(lg)
			text-align: center
	.content-wrap
		position: relative
	.accordion-list
		+content-margins
			margin-top: r(24px)

	.accordion-item
		display: flex
		+bg(white)
		padding: r(20px)
		+radius(r(8px))
		cursor: pointer
		position: relative
		.accordion-icon
			+flex-width(r(40px))
			position: absolute
			top: r(20px)
			left: r(20px)
			span
				+circle(40px)
				+bg(white)
				+text(primary-600)
				+flex-center
				+trans-all
				display: flex
				+fz(24px)
				box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15)
		.accordion-content
			flex: 1
			padding-left: r(64px)
			.title
				+trans-all
				display: flex
				align-items: center
				min-height: r(40px)
			.content
				display: none
		&.active
			.accordion-icon
				span
					+bg(primary-600)
					+text(white)
			.accordion-content
				.title
					+text(primary-600)