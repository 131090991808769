.home-bg-1
	position: relative
	z-index: 1
	&::before
		+pseudo
		background-image: url(../img/bg-2.png)
		background-repeat: no-repeat
		background-size: cover
		background-position: center
		width: 100%
		height: 100%
		top: r(-300px)