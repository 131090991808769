.detail-meta
	padding-bottom: r(20px)
	border-bottom: 1px solid color(gray-400)

.news-detail-content.article-content
	@extend .body-2 

.detail-popup
	width: r(1244px)
	overflow: visible
	padding: r(40px)
	border-radius: r(12px)
	.fancybox-close-small
		opacity: 1
		width: auto
		height: auto
		padding: 0
		svg
			height: r(40px)
			width: r(40px)
			path
				fill: color(primary-500)

.social-share
	display: flex
	gap: r(4px)
	a
		display: flex
		+flex-center
		font-size: r(16px)
		+box(28px)
		+bg(gray-50)
		border-radius: r(4px)
