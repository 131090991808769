.tabnav
	font-size: 0
	letter-spacing: 0
	margin: 0 auto
	padding: 0
	word-spacing: 0
	text-align: center
	white-space: nowrap
	overflow: auto
	li
		letter-spacing: normal
		word-spacing: normal
		display: inline-block
		vertical-align: middle
		& + li
			margin-left: r(32px)
		a
			@extend .body-2
			font-weight: 700
			+text(gray-600)
			text-transform: uppercase
			height: r(40px)
			border-radius: r(8px)
			border: 1px solid color(gray-600)
			padding: 0 r(36px)
			display: flex
			+flex-center
			+trans-all
		&.active
			a
				+bg(gray-600)
				+text(white)
		+on-hover
			a
				+bg(gray-600)
				+text(white)