.global-header
	+bg(white)

.header-wrap
	display: flex
	gap: r(40px)
	height: r(100px)
	align-items: center
	justify-content: space-between
	position: relative
	+mq-min(xl)
		gap: r(80px)

.menu
	> ul
		+mq-max(lg)
			+layout-col(12px)
		+mq-min(lg)
			+layout-row(20px)
			justify-content: space-between
		+mq-min(xl)
			+layout-row(40px)
		> li
			> a
				display: flex
				align-items: center
				height: r(100px)
				+fz(16px)
				font-weight: 600
				+text(gray-700)
				text-transform: uppercase
				white-space: nowrap
				+mq-max(lg)
					height: r(40px)
					+text(white)
					+fz(20px)

.menu-wrapper
	+mq-max(lg)
		position: absolute
		top: 100%
		padding: 1rem 0
		width: 100%
		left: 0
		+bg(primary-500)
		display: none
		z-index: 2
		margin-top: r(4px)
		padding: r(32px)

.header-util-wrapper
	+layout-row(12px)
	align-items: center
	justify-content: flex-end

.language
	padding: 0 r(8px)
	a
		@extend .body-5
		+text(gray-600)
		&.active
			+text(black)
			font-weight: 700
	+layout-row(8px)
		padding-left: r(8px)
		border-left: 1px solid color(gray-100)

.menu-toggle
	+box(48px)
	display: flex
	+flex-center
	+bg(primary-600)
	+fz(28px)
	+text(white)
