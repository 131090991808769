.heading-1
	+fz-lh(48px, 64px)
	+mq-max(lg)
		font-size: r(40px)
.heading-2
	+fz-lh(40px, 48px)
	+mq-max(lg)
		font-size: r(32px)
.heading-3
	+fz-lh(36px, 44px)
	+mq-max(lg)
		font-size: r(28px)
.heading-4
	+fz-lh(32px, 40px)
	+mq-max(lg)
		font-size: r(26px)
.heading-5
	+fz-lh(24px, 32px)

.body-1
	+fz-lh(20px, 28px)
.body-2
	+fz-lh(18px, 24px)
.body-3
	+fz-lh(16px, 24px)
.body-4
	+fz-lh(14px, 20px)
.body-5
	+fz-lh(12px, 16px)
