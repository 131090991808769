.news-item
	display: flex
	border: 2px solid rgba(217, 217, 217, 0.4)
	border-radius: r(12px)
	overflow: hidden
	+trans-all
	&-vertical
		flex-direction: column
	&-img
		padding: r(8px)
	&-big
		.news-item-img
			figure
				+res-ratio(714.04,377.36)
		.news-item-caption
			padding: r(36px) r(48px)
			+mq-max(lg)
				padding: r(24px)
		.news-item-title
			@extend .heading-5
	&-horizontal
		.news-item-img
			+flex-width(r(232px))
			figure
				height: 100%
				img
					+fit-cover
		.news-item-caption
			flex: 1
			padding: r(32px) r(36px)
			+mq-max(lg)
				padding: r(24px)
	&-date
		@extend .body-4
		+text(gray-400)
	&-caption
		+layout-col(4px)
	&-title
		@extend .body-3
		font-weight: 600
		+trans-all
	&-brief
		@extend .body-3
		+line(3)
	+on-hover
		border: 2px solid rgba(39, 112, 233, 0.5)
		.news-item-title
			+text(primary-600)

.news-side-list
	display: flex
	justify-content: space-between
	flex-direction: column
	gap: r(20px)