.form-group
	+all-text-inputs
		height: r(40px)
		width: 100%
		background: transparent
		border-radius: r(4px)
		padding: 0 r(20px)
		+text(white)
		font-size: r(14px)
		+placeholder
			+text(gray-100)
			opacity: 1
	select
		height: r(40px)
		width: 100%
		+bg(white)
		border-radius: r(4px)
		padding: 0 r(20px)
		color: #333
		font-size: r(16px)
	textarea
		height: r(144px)
		padding: r(12px) r(20px)

.frm-btnwrap
	.label
		display: none

.frm-btn
	position: relative
	display: inline-block
	margin-left: auto
	min-width: r(115px)
	input[type="submit"]
		+trans-all
		+bg(white)
		+text(primary-600)
		border: 0
		height: r(40px)
		width: 100%
		cursor: pointer
		font-size: r(14px)
		text-transform: uppercase
		font-weight: 500
		border-radius: r(4px)