.product-item
	display: block
	&-img
		position: relative
		figure
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05)
			+res-ratio(1,1)
			+bg(white)
			border-radius: 50%
			overflow: hidden
			&::before
				+pseudo
				background: rgba(97, 174, 249, 0.5)
				z-index: 2
				+fill
				+trans-all
				opacity: 0
			img
				object-fit: contain
	&-icon
		position: absolute
		+center(both)
		+circle(72px)
		display: flex
		+flex-center
		+fz(36px)
		+text(white)
		+bg(primary-600)
		z-index: 3
		+trans-all
		opacity: 0
	&-caption
		margin-top: r(24px)
		text-align: center
	&-title
		font-weight: 500
		@extend .body-2
		+text(gray-800)
	&-price
		font-weight: 600
		@extend .body-1
		+text(primary-500)
		margin-top: r(8px)
	&:hover
		.product-item-img
			figure
				&::before
					opacity: 1
		.product-item-icon
			opacity: 1