.gallery-item
	display: block
	position: relative
	&-img
		figure
			+res-ratio(480,360)
			border-radius: r(12px)
			overflow: hidden
			+bg(gray-500)
			img
				opacity: 0.3
	&-icon
		position: absolute
		+center(both)
		+fz(96px)
		+text(white)
		opacity: 0.8