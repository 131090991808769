.section-large
	padding: r(80px) 0
	+mq-max(lg)
		padding: r(32px) 0

.section-medium
	padding: r(72px) 0
	+mq-max(lg)
		padding: r(32px) 0

.section
	padding: r(64px) 0
	+mq-max(lg)
		padding: r(32px) 0

.section-small
	padding: r(48px) 0
	+mq-max(lg)
		padding: r(32px) 0