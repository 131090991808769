.product-featured-item
	&-heading
		display: flex
		justify-content: space-between
		align-items: flex-end
		margin-bottom: r(12px)
	&-title
		@extend .heading-4
		font-weight: 700
		+text(primary-600)
	&-leading
		font-weight: 600
		+text(gray-400)
		@extend .body-3
	&-price
		@extend .heading-5
		+text(gray-800)
		font-weight: 600
	&-attr
		max-height: r(542px)
		overflow: auto
		+scrollbars(r(4px), color(gray-800), rgba(color(gray-400) , 0.1))
		padding-right: r(16px)
		.accordion-item
			& + .accordion-item
				border-top: 1px solid rgba(color(gray-400) , 0.1)
			.accordion-title
				padding: r(8px) r(16px)
				+bg(gray-100)
				+text(gray-400)
				font-weight: 700
				@extend .body-4
				cursor: pointer
				p
					&::before
						content: '•'
						margin-right: r(8px)
			.accordion-body
				padding: r(24px) 0
				display: none
				+text(gray-400)
				table
					width: 100%
					border-collapse: separate
					border-spacing: 0
					th,td
						padding: r(8px)
						text-align: center
						border: 1px solid color(gray-100)
					th
						+bg(gray-100)
						font-weight: 600
						border-top: 0
					thead
						tr:first-child th:first-child
							border-top-left-radius: r(8px)
						tr:first-child th:last-child
							border-top-right-radius: r(8px)
					tbody
						border: 1px solid color(gray-100)
						tr:last-child td:first-child
							border-bottom-left-radius: r(8px)
						tr:last-child td:last-child
							border-bottom-right-radius: r(8px)

				ul
					list-style: none
					padding-left: 0
					li
						margin-bottom: r(8px)
						padding-bottom: r(8px)
						border-bottom: 1px solid rgba(color(gray-400) , 0.5)
