.product-detail-wrap
	max-width: r(1000px)
	margin: 0 auto
.product-detail-container
	+row(80px)
	+mq-max(md)
		+row(32px)
	.product-detail-img-wrap
		+flex-width(492 / 1000 * 100%)
		+mq-max(md)
			+flex-width(100%)
		figure
			+res-ratio(1,1)
			border: 2px solid #F0F0F0
			img
				object-fit: contain
	.product-detail-content-wrap
		+flex-width(508 / 1000 * 100%)
		+mq-max(md)
			+flex-width(100%)
	.product-detail-divider
		border: 1px dashed rgba(56, 56, 56, 0.5)
	.product-detail-info
		display: flex
		gap: r(20px)
		> *
			flex: 1
	.product-detail-code
		display: flex
		gap: r(10px)
		align-items: center
		span
			white-space: nowrap
		p
			padding: r(8px) r(10px)
			border: 1px solid #D9D9D9
			display: flex
			align-items: center
			flex: 1
			height: r(32px)

	.product-detail-quantity
		display: flex
		gap: r(10px)
		align-items: center

	.product-options
		ul
			display: flex
			flex-wrap: wrap
			gap: r(12px)
			li
				a
					display: flex
					+flex-center
					height: r(32px)
					padding: 0 r(16px)
					@extend .body-3
					border-radius: r(4px)
					border: 1px solid #D9D9D9
					&.active
						border: 1px solid color(primary-600)
						+text(primary-600)


.spin-input-wrap
	+layout-row(16px)
	border: 1px solid color(gray-100)
	align-items: center
	padding: r(2px)
	.btn-spin,.spin-btn
		+box(36px)
		display: flex
		+flex-center
		+bg(gray-100)
		+text(gray-400)
		cursor: pointer
	input
		border: 0
		text-align: center
		+box(32px)
		+fz(16px)