$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)
    
@each $color, $value in $button
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, #333, #fff)
		+on-hover
			background-color: darken($value, 10%)

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, #333, #fff)

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn
	padding: r(12px) r(16px)
	display: inline-flex
	+trans-all
	+fz(14px)
	+flex-center
	height: r(42px)
	white-space: nowrap
	border-radius: 2px
	min-width: r(132px)
	+content-margins
		display: inline-block
		margin-left: r(8px)
	span
		+fz(16px)

.btn-group
	display: flex
	gap: r(20px)

.swiper-btn
	display: inline-flex
	+flex-center
	+circle(60px)
	+text(primary-600)
	font-size: r(24px)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	background: rgba(217, 217, 217, 0.3)
	+remove(lg,max)
	+on-hover
		+bg(primary-600)
		+text(white)
	&.swiper-button-disabled
		opacity: 0
		pointer-events: none


.swiper-navigation
	&.is-between
		.swiper-btn
			position: absolute
			+center(Y)
			margin: 0 r(30px)
			&.swiper-prev
				right: 100%
			&.swiper-next
				left: 100%
			+mq-max(2xl)
				margin: 0 r(10px)

		&.is-no-gap
			.swiper-btn
				margin: 0
				&.swiper-prev
					right: auto
					left: 0
				&.swiper-next
					left: auto
					right: 0
	&.is-left
		display: flex
		gap: r(12px)